import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import Form from "./pages/form";
import Upload from "./pages/upload";
import Consent from "./pages/consent";

function App() {
  const [stage, setStage] = useState("form");
  const [firma, setFirma] = useState("");
  const [nompaciente, setNompaciente] = useState("");
  const [numdocpaciente, setNumdocpaciente] = useState("");
  const [tipodocpaciente, setTipodocpaciente] = useState("");
  const [nomresponsable, setNomresponsable] = useState("");
  const [numdocresponsable, setNumdocresponsable] = useState("");
  const [tipodocresponsable, setTipodocresponsable] = useState("");
  const [convenio, setConvenio] = useState("");
  const [plan, setPlan] = useState("");
  const [celular, setCelular] = useState("");
  const [orden, setOrden] = useState([]);
  const [nombreorden, setNombreorden] = useState("");
  const [habilitador, setHabilitador] = useState(true);
  const [asistido, setAsistido] = useState("");
  const [enabler,setEnabler]=useState(false)

  useEffect(() => {
    if ((convenio == "Ecopetrol (contrato en renovaciòn - no se aceptan solicitudes)")) {
      setEnabler(true)
    } else {
      setEnabler(false)
    }
  }, [convenio]);

  const Next = () => {
    switch (stage) {
      case "form":
        if (
          nompaciente === "" ||
          numdocpaciente === "" ||
          tipodocpaciente === "" ||
          nomresponsable === "" ||
          numdocresponsable === "" ||
          tipodocresponsable === "" ||
          plan === "" ||
          convenio === "" ||
          celular === ""
        ) {
          alert("Ingrese todos los datos");
        } else {
          setStage("upload");
        }

        break;
      case "upload":
        if (asistido === "Siguiente") {
          setStage("consent");
        } else if (asistido === "Enviar") {
          Send();
        }

        break;
    }
  };

  const Previous = () => {
    switch (stage) {
      case "upload":
        setStage("form");
        break;
      case "consent":
        setStage("upload");
        break;
    }
  };

  const UploadFile = async () => {
    const data = new FormData();
    data.append("userfile", orden);
    axios
      .post("https://ordenes-sede.riie.com.co/server/upload_order.php", data)
      .then((response) => {
        alert("Archivo subido exitosamente");
        setNombreorden(response.data);
        if (response.data === "") {
          setHabilitador(true);
        } else {
          setHabilitador(false);
        }
      });
  };

  const Send = async () => {
    if (asistido === "Siguiente") {
      var datos = {
        NombrePaciente: nompaciente,
        DocumentoPaciente: numdocpaciente,
        TipoDocPaciente: tipodocpaciente,
        NombreResponsable: nomresponsable,
        DocumentoResponsable: numdocresponsable,
        TipoDocResponsable: tipodocresponsable,
        Proveedor: convenio,
        Plan: plan,
        Firma: firma,
        Celular: celular,
        LinkOrden:
          "https://ordenes-sede.riie.com.co/server/ordenes/" + nombreorden,
        Asistido: "No",
      };
      if (firma === "") {
        alert("Ingrese su firma");
      } else {
        try {
          await axios.post(
            "https://ordenes-sede.riie.com.co/server/registro_orden.php",
            datos
          );
          alert("Envio exitoso");
        } catch (err) {
          console.log(err.response.data);
        }
      }
    } else if (asistido === "Enviar") {
      var datos = {
        NombrePaciente: nompaciente,
        DocumentoPaciente: numdocpaciente,
        TipoDocPaciente: tipodocpaciente,
        NombreResponsable: nomresponsable,
        DocumentoResponsable: numdocresponsable,
        TipoDocResponsable: tipodocresponsable,
        Proveedor: convenio,
        Plan: plan,
        Firma: "NO APLICA",
        Celular: celular,
        LinkOrden:
          "https://ordenes-sede.riie.com.co/server/ordenes/" + nombreorden,
        Asistido: "Si",
      };
      try {
        await axios.post(
          "https://ordenes-sede.riie.com.co/server/registro_orden.php",
          datos
        );
        alert("Envio exitoso");
      } catch (err) {
        console.log(err.response.data);
      }
      
    }
    window.location.replace(
      "https://www.ordenes-sede.riie.com.co/",
      "_blank"
    );
  };

  switch (stage) {
    case "form":
      return (
        <>
          <Form
            Next={Next}
            setNompaciente={setNompaciente}
            setNumdocpaciente={setNumdocpaciente}
            setTipodocpaciente={setTipodocpaciente}
            setNomresponsable={setNomresponsable}
            setNumdocresponsable={setNumdocresponsable}
            setTipodocresponsable={setTipodocresponsable}
            setConvenio={setConvenio}
            setPlan={setPlan}
            setCelular={setCelular}
            setAsistido={setAsistido}
            convenio={convenio}
            enabler={enabler}
          />
        </>
      );
      break;
    case "upload":
      return (
        <>
          <Upload
            Next={Next}
            Previous={Previous}
            habilitador={habilitador}
            setOrden={setOrden}
            UploadFile={UploadFile}
            asistido={asistido}
          />
        </>
      );
      break;
    case "consent":
      return (
        <>
          <Consent
            Previous={Previous}
            setFirma={setFirma}
            Send={Send}
            nompaciente={nompaciente}
            docpaciente={numdocpaciente}
            nomresponsable={nomresponsable}
            docresponsable={numdocresponsable}
          />
        </>
      );
      break;
    default:
      break;
  }
}

export default App;
